/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}

/* Default css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #fff !important;
  color: #282c34  !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}
 
p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}
/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #441745; 
  /*background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);*/
  /* background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.8) -5.91%, rgba(74, 47, 189, 0.8) 111.58%); */
  /* background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(128, 39, 253, 0.5) 50%, rgba(74, 47, 189, 0.5) 111.58%); */
}
nav.navbar a.navbar-brand {
    width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    color: #fff;
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid #fff;
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #d72952;   /*rosa*/
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
  color: #fff;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #d72952; /*rosa*/
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #fff;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin-top: 10px;
  padding: 130px 0 120px 0;
  background-image: url('./assets/img/white.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
/* Estilo del enlace */
.tagline {
  display: flex;
  flex-direction: column; /* Los elementos se apilan verticalmente */
  align-items: center;    /* Centra horizontalmente */
  justify-content: center; /* Centra verticalmente */
  text-decoration: none; /* Evita subrayados en el texto del enlace */
}
/* Estilo del botón dentro del enlace */
.tagline button {
  font-weight: 700;
  letter-spacing: 0.8px;
  color: #fff;
  padding: 8px 20px;
  background-color: #441745;
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin: 0 auto;
  text-align: center;
  cursor: pointer; /* Cambia el cursor al estilo de un botón */
  transition: background-color 0.3s ease; /* Suaviza el cambio de color al hacer hover */
}
/* Hover para el botón */
.tagline button:hover {
  background-color: #d72952;
}
.tagline button span {
  color: #fff;
}
.banner h1 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
  text-align: center;
}
.banner p {
  color: #282c34;
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
  text-align: center;
}
.banner a {
  text-decoration: none;
}
.banner button {
  color: #282c34;
  font-weight: 700;
  font-size: 20px;
  margin-top: 20px;
  letter-spacing: 0.8px;
  display: flex;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner span {
  align-items: center;
  text-align: center;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
  padding-top: 20px;
}
.banner img.no-animation {
  animation: none;
}
.no-animation {
  width:60%; /* Ajusta según el tamaño deseado */
  height: auto; /* Mantiene la proporción del logo */
  display: block; 
  margin: 0 auto;
}
/* Opcional: Efecto suave al interactuar */
.no-animation:hover {
  transform: scale(1.05); /* Un pequeño zoom */
  transition: transform 0.3s ease-in-out;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/************ Reseñas Css ************/
.category {
  padding: 0 0 50px 0;
  position: relative;
}
.category-bx {
  background-color: #441745;
  /*background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);   */
  /* background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(128, 39, 253, 0.5) 50%, rgba(74, 47, 189, 0.5) 111.58%);   */
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
.category h2 {
	font-size: 45px;
	font-weight: 700;
  color: #fff;
}
.category-subtitle {
  color: #fff;
  font-size: 20px !important;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 10px 30px 10px 30px;
}
.category p {
    color: #fff;
    font-size: 12px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 10px 30px 10px 30px;
}
.category-slider {
  width: 90%;
  margin: 0 auto;
  position: relative;
}
.category-slider .item img {
  width: 70%;
  margin: 0 auto 15px auto;
  border-radius: 10px;
  border: 5px solid #d72952; /* Borde dorado */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19); /* Sombra elegante */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animación suave */
}

.category-slider .item img:hover {
  transform: scale(1.05); /* Aumenta ligeramente el tamaño al pasar el cursor */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3), 0 12px 40px rgba(0, 0, 0, 0.25); /* Mayor sombra en hover */
}

.category h5{
  color: #fff;
}
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

/************ Events Css ************/
.event {
  padding: 80px 0;
  position: relative;
  background-color: #fff;
}
.event h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}
.event p {
  color: #282c34;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.event .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.event .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.event .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.event .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background-color: #fff;
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.event .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.event .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#events-tabs-tab-first {
  border: 1px solid  rgba(68, 10, 101, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#events-tabs-tab-second {
  border-top: 1px solid rgba(68, 10, 101, 0.5);
  border-bottom: 1px solid rgba(68, 10, 101, 0.5);
}
.nav-link#events-tabs-tab-third {
  border: 1px solid rgba(68, 10, 101, 0.5);
  border-radius: 0 55px 55px 0;
} 
.event-card-col {
  margin-bottom: 32px; 
}
.even-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 16px;
}
.even-imgbx::before {
  content: "";
  background-color: #441745;
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.even-imgbx:hover::before {
  height: 100%;
}
.even-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.even-imgbx:hover .even-txtx {
  top: 50%;
  opacity: 1;
}
.even-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}
.buy-button {
  margin-top: 20px;
  display: block;
  width: 100%;
  background-color: #441745;
  border: none;
  color: #fff;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
}
.buy-button:hover {
  background-color: #d72952;
}
.cost-section {
  font-weight: bold;
  font-size: 14px;
  color: #fff;
  margin: 10px 0;
}

/************ Events Css ************/
.contact {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 60px 0 200px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Location Css ************/
.location {
  padding: 80px 0;
  background-color: #fff;
  position: relative;
}

.location h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #441745;
}

.location h5 {
  font-size: 24px;
  color: #282c34;
  margin-bottom: 25px;
}

.location p {
  font-size: 18px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 15px;
}

.location img {
  align-items: flex-start;
  max-width: 120%;
  height: auto;
  margin-left: -10%;
  margin-top: -10%;
}

.location iframe {
  border-radius: 20px;
  margin-top: 25px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  height: 450px;
}

.schedule-container {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 15px;
  margin: 20px 0;
  border-left: 4px solid #441745;
}

.schedule-container p:first-child {
  font-weight: 700;
  color: #441745;
  margin-bottom: 10px;
}

/************ PurchasePage Css ************/
.purchase-page-container {
  background-color: #fff; /* Fondo claro */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
}
.purchase-page h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}
.purchase-page button {
  margin-top: 20px;
  width: 100%;
  background-color: #441745;
  border-color: #441745;
}
.purchase-page button:hover {
  background-color: #AA367C;
}

/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
  background-color: #441745;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.newsletter-bx {
  background: #FFFFFF;
  border-radius: 55px;
  color: #121212;
  padding: 85px 125px;
  margin-top: -122px;
}
.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}
.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}
.new-email-bx a {
  width: 100%;
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 20px 65px;
  color: #fff;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}
.experience-image {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}
.footer img {
  width: 30%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

.buyer-info {
  background-color: #f8f9fa; /* Gris claro */
  padding: 10px;
  border-radius: 5px; /* Bordes redondeados */
  margin-bottom: 10px; /* Separación inferior */
  color: #343a40; /* Texto oscuro */
}

.footer .support-copy p {
  font-size: 14px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 10px;
}

.footer .support-copy a {
  color: #007bff;
  text-decoration: none;
}

.footer .support-copy a:hover {
  text-decoration: underline;
}

/************ Purchase Form Css ************/
.ticket-container {
  display: flex;
  gap: 30px;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 10% auto 0 auto;
}

.event-details-container {
  flex: 1;
  min-width: 300px;
}

.ticket-form-container {
  flex: 1;
  min-width: 300px;
  background: #fff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.purchase-page-container {
  border: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  overflow: hidden;
}

.purchase-page-container .card-img-top {
  height: 300px;
  object-fit: cover;
}

.purchase-page-container .card-body {
  padding: 25px;
}

.purchase-page-container .card-title {
  font-size: 28px;
  font-weight: 700;
  color: #282c34;
  margin-bottom: 15px;
}

.purchase-page-container .card-text {
  margin-bottom: 15px;
  line-height: 1.6;
}

.ticket-form-container form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ticket-form-container .form-control {
  border-radius: 10px;
  padding: 12px;
  border: 1px solid #ddd;
}

.ticket-form-container .btn-primary {
  background-color: #441745;
  border: none;
  padding: 15px;
  font-weight: 600;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.ticket-form-container .btn-primary:hover {
  background-color: #d72952; /*rosa*/
}

.total-price {
  font-size: 24px;
  font-weight: 700;
  color: #441745;
  text-align: right;
  margin: 20px 0;
}

@media (max-width: 768px) {
  .ticket-container {
    flex-direction: column;
  }
  
  .event-details-container,
  .ticket-form-container {
    width: 100%;
  }
}

.ticket-form-container .form-title {
  font-size: 24px;
  font-weight: 700;
  color: #282c34;
  margin-bottom: 25px;
  text-align: center;
}

/*Pagina de venta Success*/
.success-container {
  margin-top: 10%;
  background-color: #fff; /* Fondo claro */
  color: #212529; /* Texto oscuro */
  min-height: 100vh; /* Ocupa toda la altura de la pantalla */
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-card {
  background-color: white; /* Fondo del contenedor */
  padding: 30px; /* Espaciado interno */
  border-radius: 10px; /* Bordes redondeados */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Sombra para realce */
  text-align: center; /* Centrar el texto */
}

.success-title {
  font-size: 2rem; /* Tamaño del título */
  color: #441745; /* Color del texto del título */
  margin-bottom: 20px; /* Separación inferior */
}

.success-icon {
  font-size: 4rem; /* Tamaño del ícono */
  color: #441745; /* Color del ícono */
}

.success-message {
  font-size: 1.25rem; /* Tamaño del mensaje */
  color: #6c757d; /* Color gris claro */
  margin-bottom: 20px; /* Separación inferior */
}

.btn-primary {
  background-color: #441745; /* Color del botón */
  color: white; /* Color del texto */
  border: none; /* Sin bordes */
  padding: 10px 20px; /* Espaciado interno */
  border-radius: 5px; /* Bordes redondeados */
  font-size: 1rem; /* Tamaño del texto */
}

.btn-primary:hover {
  background-color: #d72952; /* Color en hover */
  cursor: pointer; /* Cambia el cursor al pasar por encima */
}



/*Pagina de venta  PurchaseSummary*/
#purchase-summary {
  color: black; /* Asegura que el texto sea negro */
  background-color: #f8f9fa; /* Fondo claro */
  font-family: Arial, sans-serif; /* Fuente legible */
  padding: 20px; /* Espaciado interno */
  border-radius: 8px; /* Bordes redondeados */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para realce */
  border: 1px solid #ddd; /* Borde del contenedor */
}

#purchase-summary h2 {
  font-size: 1.5rem; /* Tamaño del título */
  margin-bottom: 20px; /* Separación del título */
  text-align: center; /* Centra el título */
}

#purchase-summary p {
  font-size: 1rem; /* Tamaño del texto */
  margin: 5px 0; /* Espaciado entre párrafos */
  line-height: 1.5; /* Altura de línea */
}

#purchase-summary img {
  max-width: 200px; /* Tamaño máximo del QR */
  margin-top: 20px; /* Separación superior */
  border: 1px solid #ddd; /* Borde de la imagen */
  border-radius: 8px; /* Bordes redondeados */
}

.button-container {
  margin-top: 20px; /* Separación del botón */
}

.btn-secondary {
  background-color: #d72952; /* Color del botón */
  color: white; /* Color del texto */
  border: none; /* Sin bordes */
  padding: 10px 20px; /* Espaciado interno */
  border-radius: 5px; /* Bordes redondeados */
  font-size: 1rem; /* Tamaño del texto */
}

.btn-secondary:hover {
  background-color: #441745; /* Color en hover */
  cursor: pointer; /* Cambia el cursor al pasar por encima */
}

